<template>
  <div class="grid" style="padding-top: 20px">
    <div class="col-12 xl:col-12 sm:col-12 .col-12">
      <h3
        style="
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 140.62%;
          color: rgba(0, 0, 0, 0.7);
        "
      >
        Micro-organismos de ocorrência única
      </h3>
      <h4
        style="
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 19px;
          color: #585857;
        "
      >
        Nesta seção, listamos os táxons de micro-organismos a nível de espécie
        ou gênero de ocorrência favorecida em um dos tratamentos, ou seja,
        aparecem apenas onde o Produto foi aplicado ou apenas na parcela
        Controle. Esta visualização permite avaliar os micro-organismos que
        foram estimulados ou recrutados junto a rizosfera após a entrada do
        produto no solo.
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
